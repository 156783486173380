/* eslint-disable */
import styles from './styles.module.css';
import React, {useContext, useEffect, useRef, useState} from "react";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {checkIsValidId, DaySummary, IDType, MAX_ENTRIES_PER_DAY} from "../../shared/types";
import {DayEntry} from "./dayentry";
import {SimpleI18NMessage, useSimpleI18n} from '../../utils/i18n';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import {TUT_1_CLASSNAME} from "../tutorial";

const _EQSCRIB_FIRST_DAY = new Date(2023, 10, 1)
export function dateToDaysSinceNov12023(date: Date|undefined=undefined): number {
    // remove time from date and create new date
    if (date === undefined) {
        date = new Date()
    }
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const diff = (date as any) - (_EQSCRIB_FIRST_DAY as any);
    return Math.round(diff / 24 / 60 / 60 / 1000);
}

function dayZeroToDay(number: number): Date {
    const date = new Date(_EQSCRIB_FIRST_DAY.getFullYear(), _EQSCRIB_FIRST_DAY.getMonth(), _EQSCRIB_FIRST_DAY.getDate());
    date.setDate(date.getDate() + number);
    return date;
}

interface FetchingInfo {
    startDate?: string;
    endDate?: string;
    isFetching?: boolean;
    isFetched?: boolean;
    isErrored?: boolean;
    errorMessage?: string;
    items?: {[key: string]: DaySummary};
}

const ESInfiniView = () => {

    const esApi = useContext(EsApiContext)
    const mainDivRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const fetchDataTimeout = useRef<NodeJS.Timeout | null>(null);
    const fetchCancelTokens = useRef([] as AbortController[]);
    const fetchInfoRef = useRef({} as FetchingInfo);
    const [mainDivHeight, setMainDivHeight] = useState(512);
    const [firstDay, setFirstDay] = useState(0);
    const nowAt = dateToDaysSinceNov12023(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [isTooSmall, setIsTooSmall] = useState(false);
    const [canView, setCanView] = useState(false);
    const [fetchingInfo, setFetchingInfo] = useState<FetchingInfo>({});

    const i18n = useSimpleI18n();

    useEffect(() => {
        const updateSize = () => {
            setMainDivHeight(mainDivRef.current?.clientHeight || 0);
        };

        window.addEventListener('resize', updateSize);

        // Clean up
        return () => {
            window.removeEventListener('resize', updateSize);
        };
    }, []);

    useEffect(() => {
        fetchInfoRef.current = fetchingInfo;
    }, [fetchingInfo]);

    useEffect(() => {
        const container = containerRef.current;
        if (canView && container) {
            container.addEventListener('scroll', handleScroll);
            handleScroll()
            return () => {
                container.removeEventListener('scroll', handleScroll);
            }
        }
        return () => {};
    }, [canView]);

    function fetchData(startDate: string, endDate: string) {
        if (fetchInfoRef.current.startDate && fetchInfoRef.current.endDate &&
            startDate >= fetchInfoRef.current.startDate && endDate <= fetchInfoRef.current.endDate) {
            return;
        }
        checkIsValidId(startDate, IDType.CHILD);
        checkIsValidId(endDate, IDType.CHILD);
        fetchCancelTokens.current.forEach((t) => {
            t.abort('Cancelling previous scroll fetch');
        })
        const cancelToken = esApi.getCancelToken();
        fetchCancelTokens.current = [cancelToken];
        setFetchingInfo((prev) => (
            {
                startDate: !prev.startDate || startDate < prev.startDate ? startDate : prev.startDate,
                endDate: !prev.endDate || endDate > prev.endDate ? endDate : prev.endDate,
                isFetching: true,
                errorMessage: "",
                items: prev.items,
            } as FetchingInfo
        ))
        esApi.getSummaries(startDate, endDate, cancelToken).then((entries) => {
            setFetchingInfo((prev) => {
                const items = prev.items || {};
                for (const entry of entries) {
                    if (!entry) {
                        continue;
                    }
                    const dateStr = `${entry.year}${entry.month.toString().padStart(2, '0')}${entry.day.toString().padStart(2, '0')}`;
                    items[dateStr] = entry;
                }
                return {
                    startDate: prev.startDate,
                    endDate: prev.endDate,
                    isFetching: false,
                    isFetched: true,
                    isErrored: false,
                    items: items,
                    errorMessage: ""
                } as FetchingInfo;
            });
        })
            .catch((e) => {
                if (e.name === 'CanceledError') {
                    // someone else will update the state
                    return;
                }
                setFetchingInfo((prev) => ({
                    startDate: prev.startDate,
                    endDate: prev.endDate,
                    isFetching: false,
                    isFetched: false,
                    isErrored: true,
                    items: prev.items,
                    errorMessage: e.message
                }));
            });
    }

    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) {
            return;
        }
        const {scrollTop, scrollHeight, clientHeight} = container;
        const numberOfDays = nowAt - firstDay;
        const heightPerItem = scrollHeight / numberOfDays;
        const itemNumber = Math.floor(scrollTop / heightPerItem);
        const itemsPerPage = Math.floor(clientHeight / heightPerItem) + 3;
        const dateAt = dayZeroToDay(nowAt - itemNumber);
        const lastDateAt = dayZeroToDay(nowAt - itemNumber - itemsPerPage - 1);
        // startDateStr = dateAt as YYYYMMDD.0
        // endDateStr = dateAs as YYYYMMDD.9

        const endDateStr = dateAt.toISOString().substring(0, 10).replace(/-/g, '') + '.' + (MAX_ENTRIES_PER_DAY - 1)
        const startDateStr = lastDateAt.toISOString().substring(0, 10).replace(/-/g, '') + '.0'
        if (fetchDataTimeout.current) {
            clearTimeout(fetchDataTimeout.current);
        }
        fetchDataTimeout.current = setTimeout(() => {
            fetchData(startDateStr, endDateStr);
        }, 250);
    }


    useEffect(() => {
        setIsTooSmall(!isLoading && mainDivHeight < 512);
    }, [mainDivHeight, isLoading]);

    useEffect(() => {
        setCanView(!isTooSmall && !isLoading);
    }, [isTooSmall, isLoading])

    useEffect(() => {
        gtag_report_conversion();
        esApi.getScribEntries("first")
            .then((entries) => {
                if (entries.length <= 0) {
                    setFirstDay(0);
                } else {
                    // set first day, date of the first entry minus 30 days, but not before EQSCRIB_FIRST_DAY
                    const firstDateAsYYYYMMDD = entries[0].id.substring(0, 8);
                    const firstDate = dateToDaysSinceNov12023(new Date(
                        parseInt(firstDateAsYYYYMMDD.substring(0, 4)),
                        parseInt(firstDateAsYYYYMMDD.substring(4, 6)) - 1,
                        parseInt(firstDateAsYYYYMMDD.substring(6, 8))
                    )) - 30;
                    if (firstDate < 0) {
                        setFirstDay(0);
                    } else {
                        setFirstDay(firstDate);
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setFirstDay(0);
                setIsLoading(false);
            })
    }, [esApi]);


    return (
        <div className={styles.mainContainer + ' ' + TUT_1_CLASSNAME} ref={mainDivRef}>
            {isLoading && <div className={styles.loading}><SimpleI18NMessage msg={'Loading...'}/></div>}
            {isTooSmall && <div className={styles.loading}><SimpleI18NMessage msg={'Device Too Small To Display Entries'}/><div><ScreenRotationIcon/></div></div>}
            {canView && (
                <div className={styles.mainCalScrollParent}>
                    <div className={styles.mainCalScroll} ref={containerRef}>
                        {
                            Array.from({length: nowAt - firstDay}, (_, i) => {
                                const day = dayZeroToDay(nowAt - i);
                                // convert day to YYYYMMDD
                                const dayStr = day.toISOString().substring(0, 10).replace(/-/g, '');
                                const entry = fetchingInfo.items?.[dayStr];
                                let errorStr = "";
                                if (fetchingInfo.isErrored) {
                                    errorStr = fetchingInfo.errorMessage || i18n("Error: Unknown Error");
                                } else if (!fetchingInfo.startDate || dayStr < fetchingInfo.startDate) {
                                    errorStr = i18n("Loading...")
                                } else if (!fetchingInfo.endDate || dayStr > fetchingInfo.endDate) {
                                    errorStr = i18n("Loading...")
                                }
                                return (
                                    <DayEntry
                                        key={dayStr}
                                        date={dayStr}
                                        loadingEntry={!!(!entry && fetchingInfo.isFetching)}
                                        error={!entry ? errorStr : ''}
                                        entries={entry?.entries || []}
                                        totalEntriesLoaded={0}/>
                                )})
                        }
                    </div>
                </div>
            )}
        </div>
    )
}


export default ESInfiniView;