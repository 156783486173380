import React, {useContext, useEffect, useRef, useState} from "react";
import Joyride, {CallBackProps, Step} from "react-joyride";
import {useLocation} from "react-router-dom";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {I18N, useSimpleI18n} from "../../utils/i18n";
import {PersonaSelectionMatrix} from "../personaselection";
import {Character, UserSettings} from "../../shared/types";
import { DialogContent } from "@mui/material";

import styles from './styles.module.css';

export const TUT_1_CLASSNAME = 'tutorial1'
export const TUT_2_CLASSNAME = 'tutorial2'
export const TUT_3_CLASSNAME = 'tutorial3'
export const TUT_4_CLASSNAME = 'tutorial4'
export const TUT_5_CLASSNAME = 'tutorial5'
export const TUT_6_CLASSNAME = 'tutorial6'
export const TUT_7_CLASSNAME = 'tutorial7'



type JrProps = {
    idx: number,
    mainRoute: string
}

const STEPS_RUN = [false, false, false, false]

const BASE_CHARACTER = {
    name: 'author, narrator',
    description: '',
} as Character


const JoyRideN = (Props: JrProps) => {
    const {idx, mainRoute} = Props;
    const esApi = useContext(EsApiContext);
    const location = useLocation()
    const i18n = useSimpleI18n()

    const [run, setRun] = useState(false);
    const [settings, setSettings] = useState<UserSettings>();
    const timerRef = useRef<number | undefined>(undefined);

    useEffect(() => {
        async function startup() {
            const settings = await esApi.getSettings();
            const narrator = settings.characters[0] || BASE_CHARACTER;
            let narratorUpdated = narrator
            if (!narrator.name) {
                narratorUpdated = {...narrator, name: BASE_CHARACTER.name}
            }
            settings.characters[0] = narratorUpdated;
            setSettings({...settings})
        }

        startup().then()
    }, [esApi]);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
    }, []);


    function dequeueNewSettingsUpdate() {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = window.setTimeout(async () => {
            // save settings, but re-read in case something's changed, to make sure we've got latest
            // of attributes we don't care about
            console.log(`Updating settings. Tutorials completed are ${settings!.tutorialsCompleted}`);
            const oldSettings = await esApi.getSettings();
            // Merge tutorial completions to avoid losing any
            const allTutorials = new Set([
                ...(oldSettings.tutorialsCompleted || []),
                ...(settings!.tutorialsCompleted || [])
            ]);
            const newSettings = {
                ...oldSettings,
                characters: oldSettings.characters || [],
                tutorialsCompleted: Array.from(allTutorials).sort((a, b) => a - b)
            }
            newSettings.characters[0] = settings!.characters[0];
            await esApi.updateSettings(newSettings);
            setSettings(newSettings);
        }, 250)
    }

    const handleCharacterUpdate = async (character: any) => {
        const characters = settings!.characters || [];
        characters[0] = character;
        setSettings({
            ...settings!,
            characters: [character]
        })
        dequeueNewSettingsUpdate();
    }

    async function handleJoyrideCallback(data: CallBackProps, idx: number) {
        const {action, status} = data;

        console.log(`Tutorial ${idx}: Callback ${action} ${status}`)
        // Handle tutorial completion
        if (action === 'reset' || status === 'finished') {
            const tutsCompleted = settings!.tutorialsCompleted || [];
            if (!tutsCompleted.includes(idx)) {
                tutsCompleted.push(idx);
            }
            const newSettings = {
                ...settings!,
                tutorialsCompleted: tutsCompleted
            }
            setSettings(newSettings);
            dequeueNewSettingsUpdate()
        }
    }

    const TUT_STEPS: Step[][] = React.useMemo(() => [
        [
            {
                target: '.' + TUT_1_CLASSNAME,
                content: i18n('Welcome to EquiQuill. When on the home page, you will see a depiction of your journal entries.'),
                placement: 'center',
                disableBeacon: true,
            },
            {
                target: '.' + TUT_2_CLASSNAME,
                content: i18n("You can click the '+' button to add a new journal entry."),
                disableBeacon: true,
                disableScrolling: true,
            },
        ],
        [
            {
                target: '.' + TUT_3_CLASSNAME,
                placement: 'center',
                content: (<>
                        <div>
                            <I18N>Start a new journal entry by entering a few words. If you're not sure what to write,
                                write
                                about what's happened to you so far today, and what you see around you.</I18N>
                        </div>
                        <br/>
                        <div>
                            <I18N>EquiQuill needs <b>at least 100 characters</b> before it can do its magic.</I18N>
                        </div>
                    </>
                ),
                disableBeacon: true,
            },
            {
                target: '.' + TUT_4_CLASSNAME,
                content: i18n("If you have a stylus and prefer journaling by hand, you can click the 'pencil' button to toggle between keyboard and stylus."),
                disableBeacon: true,
            }
        ], [
            {
                target: '.' + TUT_5_CLASSNAME,
                content: i18n("Now that you've written a few words, you can press the play button to see your journal entry come to life."),
                disableBeacon: true,
            },
            {
                target: '.' + TUT_5_CLASSNAME,
                content: (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: 'calc(100dvh - 300px)',
                    }}>
                        <div style={{ marginBottom: '16px', textAlign: 'left'}}>
                            {i18n("But before EquiQuill can analyze your journal entry, please pick or describe your physical appearance.")}<span> </span>
                        </div>
                        <PersonaSelectionMatrix
                            isPrompt0={true}
                            character={settings?.characters[0]!}
                            onChange={handleCharacterUpdate}
                        />
                    </div>
                ),
                disableBeacon: true,
                placement: 'center',
                disableScrolling: true,
                styles: {
                    options: {
                        zIndex: 1400,
                        width: 'calc(min(1300px, 90dvw))'
                    },
                },
            }
        ], [
            {
                target: '.' + TUT_6_CLASSNAME,
                content: i18n(`Once complete, you will see an image that represents your journal entry, as
                      well as a short AI-generated psychological assessment of your entry.
                      `),
                disableBeacon: true,
                placement: 'center',
                styles: {
                    options: {
                        zIndex: 1400
                    }
                },
            },
            {
                target: '.' + TUT_7_CLASSNAME,
                content: i18n('You can always customize the appearance of the persons in your journal entries by going to settings→characters.'),
                disableBeacon: true,
                styles: {
                    options: {
                        zIndex: 1400
                    }
                },
            }
        ]
    ], [settings?.characters?.[0], i18n]);


    useEffect(() => {
        if (STEPS_RUN[idx]) {
            return;
        }
        STEPS_RUN[idx] = true;

        async function hasRun(idx: number) {
            const settings = await esApi.getSettings();
            return settings.tutorialsCompleted?.includes(idx);
        }

        async function waitForInitialCharacter() {
            if (!esApi.isLoggedIn) return false;
            const settings = await esApi.getSettings();
            return settings.characters?.[0] !== undefined;
        }

        (async () => {
            while (true) {
                if (!esApi.isLoggedIn) {
                    // console.log(`Tutorial ${idx}: Waiting for login`);
                    // do nothing, wait for log in
                } else if ((await hasRun(idx))) {
                    console.log(`Tutorial ${idx}: Tutorial has already run. Stopping`);
                    return;
                } else if (idx > 0 && !await hasRun(idx - 1)) {
                    console.log(`Tutorial ${idx}: Waiting for tutorial ${idx - 1} to complete`)
                } else {
                    const selector = TUT_STEPS[idx][0].target as string;
                    const el = document.querySelector(selector);
                    console.log(`Tutorial ${idx}: Waiting for ${selector} ${el}`);
                    if (el) {
                        break;
                    }
                }
                await new Promise(r => setTimeout(r, 1000));
            }
            setRun(true);
        })();
    }, [TUT_STEPS, esApi, idx, location.pathname, mainRoute]);

    // wait to be logged in and for location to
    return (
        <Joyride
            run={run}
            steps={TUT_STEPS[idx]}
            continuous={true}
            locale={{
                last: i18n('Close'),
                next: i18n('Next'),
                back: i18n('Back'),
            }}
            callback={(data) => handleJoyrideCallback(data, idx)}
        />
    )
}

export const JoyRide = () => {
    return (
        <>
            <JoyRideN key='jr1' idx={0} mainRoute={'escal'}/>
            <JoyRideN key='jr2' idx={1} mainRoute={'dayview/20'}/>
            <JoyRideN key='jr3' idx={2} mainRoute={'dayview/20'}/>
            <JoyRideN key='jr4' idx={3} mainRoute={'dayview/20'}/>
        </>
    )
}
