import React, {useContext, useEffect, useState} from "react";
import {Paper, Table, TableBody, TableCell, TableRow} from "@mui/material";
import styles from './styles.module.css'
import {Plan} from "../../../shared/types";
import {EsApiContext} from "../../../utils/equi-scrib-internal-api-context";
import Button from "@mui/material/Button";
import {useLocation} from "react-router-dom";
import {IS_TEST_STRIPE} from "../../../shared/utils";
import {I18N, useSimpleI18n} from "../../../utils/i18n";
import LoadingOverlay from "../../loadingoverlay";
import {Capacitor} from "@capacitor/core";
import CdvPurchaseService, {BuyLifetimeSubscriptionResult, LifetimeSubscriptionState} from "./cdvpurchaseservice";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {useSnackbar} from "../../globalsnackbar";


// noinspection SpellCheckingInspection
const liveStripePublicKey = 'pk_live_51OdJceFgOlDTaqSdPZV4QhnJ4wnnZDz091FA9uP43sVqDp6GJE43NdAh0abOySSwaLkppms9nYH5Ne61o2Sjj3Ch000rWbL3iF'
// noinspection SpellCheckingInspection
const testStripePublicKey = 'pk_live_51OdJceFgOlDTaqSdPZV4QhnJ4wnnZDz091FA9uP43sVqDp6GJE43NdAh0abOySSwaLkppms9nYH5Ne61o2Sjj3Ch000rWbL3iF'

let _APP_STORE: CdvPurchaseService|undefined = undefined
const GET_APP_STORE = () => {
    if (_APP_STORE) {
        console.log("Retrieving app store");
        return _APP_STORE
    } else {
        console.log("Instantiating app store");
        _APP_STORE = new CdvPurchaseService()
        return _APP_STORE
    }
}


function SubscriptionPage() {
    const [plan, setPlan] = React.useState('');
    const [appStorePurchaseState, setAppStorePurchaseState] = useState(undefined as undefined|LifetimeSubscriptionState)
    const [isLoading, setIsLoading] = React.useState(true);
    const esApi = useContext(EsApiContext);

    const location = useLocation()
    const i18n = useSimpleI18n()
    const snackBar = useSnackbar()
    if (!snackBar) {
        console.error("Failed to get snackbar")
    }

    const verb = location.search.includes('verb=') ? location.search.split('verb=')[1] : 'currently'

    useEffect(() => {
        console.log("Location pathname is: " + location.pathname)
        if (!location.pathname.startsWith('/settings/subscription') || !esApi.isLoggedIn) {
            return;
        }
        function listener() {
            const newState = GET_APP_STORE().lifeTimeSubscriptionState
            console.log(`App store purchase state == ${newState}`);
            setAppStorePurchaseState(newState)
        }

        GET_APP_STORE().addStateChangedListener(listener)
        const subscriptionState = GET_APP_STORE().lifeTimeSubscriptionState
        console.log(`Added state change listener. Subscription state = ${subscriptionState}`)
        if (subscriptionState === LifetimeSubscriptionState.PENDING) {
            completePurchase().then(_r => {})
        }
        return () => {
            GET_APP_STORE().removeStateChangedListener(listener)
        }
    }, [location.pathname, esApi.isLoggedIn]);

    useEffect(() => {
        esApi.getSettings(location.search.includes('verb=')).then((settings) => {
            setPlan(settings.plan || Plan.FREE)
            setIsLoading(false);
            console.log(`Retrieved settings.  Current plan is ${settings.plan}`)
        }).catch((err) => console.warn(err));
    }, [esApi, location.search]);


    const freeVsPaid = [
        ['Cost', '$0', '$99 CAD'],
        ['Secure and End-to-end encryption', '✓', '✓'],
        ['Data never shared', '✓', '✓'],
        ['Data can only be read by you', '✓', '✓'],
        ['Up to 4 entries per day', '✓ (for a limited time)', '✓'],
        ['Hand-writing and Recognition', '✓', '✓'],
        ['Auto-generated emoji', '✓', '✓'],
        ['AI-based pseudo-psychological Analysis', '✓', '✓'],
        ['AI-generated images', '✓', '✓'],
        ['Advanced AI-generated images', '✓ (for a limited time)', '✓'],
        ['Unlimited image regenerations', '✓ (for a limited time)', '✓'],
        ['Access to new features', '✓ (for a limited time)', '✓'],
    ]

    function iconize(string: string) {
        if (string.startsWith('✓')) {
            return (
                <>
                    <div className={styles.greenCheck}>✓</div>
                    <div>{i18n(string.substring(1).trim())}</div>
                </>
            )
        }
        return (
            <span>{i18n(string)}</span>
        )
    }

    async function subscribe() {
        if (Capacitor.isNativePlatform()) {
            console.log("Subscribing via native platform");
            return subscribeCdv()
        } else {
            console.log("Subscribing via web platform")
            return subscribeStripe();
        }
    }


    async function subscribeCdv() {
        const result = await GET_APP_STORE().buyLifetimeSubscription()
        if (result === BuyLifetimeSubscriptionResult.ERROR) {
            snackBar.displayMessage(i18n("Purchase of lifetime subscription failed"))
            return;
        }
        return completePurchase()
    }

    async function completePurchase() {
        console.log("Completing purchase");
        let startTime = new Date().getTime()
        await new Promise(resolve => setTimeout(resolve, 500))
        let displayedWaitingWarning = false;
        await GET_APP_STORE().refresh()
        while (true) {
            const state = GET_APP_STORE().lifeTimeSubscriptionState;
            if (state === LifetimeSubscriptionState.PURCHASED) {
                snackBar.displayMessage(i18n("Thank you for purchasing a lifetime subscription"))
                setPlan(Plan.LIFETIME)
                return;
            } else if (state === LifetimeSubscriptionState.NOT_PURCHASED) {
                snackBar.displayMessage(i18n("Purchase of lifetime subscription failed"))
                return;
            } else {
                if (!displayedWaitingWarning) {
                    snackBar.displayMessage(i18n("Waiting for purchase to complete"))
                    displayedWaitingWarning = true;
                }
                // warn every 2 seconds
                if (new Date().getTime() - startTime > 2000) {
                    await GET_APP_STORE().refresh()
                    console.log("Waiting for purchase to complete")
                    startTime = new Date().getTime()
                }
            }
            await new Promise(resolve => setTimeout(resolve, 500))
        }
    }

    async function subscribeStripe() {
        setIsLoading(true);
        const stripe = await stripePromiseTest()
        if (!stripe) {
            throw new Error('Failed to load stripe!');
        }
        const lifetimeCheckoutSession = await esApi.createLifetimeSubscriptionSession()
        if (!lifetimeCheckoutSession.id || !lifetimeCheckoutSession.url) {
            throw new Error('Failed to create lifetime subscription session!');
        }
        // redirect to stripe checkout, and that is the end of that
        console.log("Navigating to: " + lifetimeCheckoutSession.url)
        window.location.href = lifetimeCheckoutSession.url;
        // if url (before #) is the same as the current url, then reload the page
        if (window.location.href.split('#')[0] === lifetimeCheckoutSession.url.split('#')[0]) {
            console.log("Navigating to same URL, so forcing reload")
            setTimeout(() => {
                window.location.reload()
            }, 10);
        }
    }

    if (isLoading) {
        return (<LoadingOverlay/>)
    }

    return (
        <Paper className={styles.main}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <p><I18N>You are {verb} subscribed to a <b>{plan}</b> plan.</I18N></p>
                            {plan === Plan.LIFETIME && (<p><I18N>Thank you for your support.</I18N></p>)}
                            {plan === Plan.FREE && (<>
                                    <p><I18N>
                                        Rest assured that in alignment with our mission and vision, a free, secure, and
                                        private version with <b>core</b> features will always be available.</I18N>
                                    </p><p>
                                    <I18N>However, running the platform today is costing <b>us</b> about $0.25
                                        per day per user. As we expand, maintaining a completely free
                                        service will be challenging. Opting for a lifetime membership now guarantees
                                        full unlimited access, forever, to all features.</I18N>
                                </p></>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={styles.selectAPlan}></TableCell>
                        <TableCell className={styles.plan}>
                            <div><I18N>Free</I18N></div>
                        </TableCell>
                        <TableCell className={styles.plan}>
                            <div><I18N>Lifetime</I18N></div>
                            <Button disabled={plan === Plan.LIFETIME
                                ||  (Capacitor.isNativePlatform() &&
                                appStorePurchaseState !== LifetimeSubscriptionState.NOT_PURCHASED
                                )
                            }
                                    variant="contained" color="primary"
                                    onClick={subscribe}>
                                <div>
                                    <div>{appStorePurchaseState === LifetimeSubscriptionState.PENDING &&
                                        <HourglassBottomIcon className={styles.animated}/>}</div>
                                    <div><I18N>Subscribe</I18N></div>
                                </div>
                            </Button>
                        </TableCell>
                    </TableRow>
                    {freeVsPaid.map((v, index) => (
                        <TableRow key={index}>
                            <TableCell>{i18n(v[0])}</TableCell>
                            <TableCell className={styles.centerText}>{iconize(v[1])}</TableCell>
                            <TableCell className={styles.centerText}>{iconize(v[2])}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );

}

export default SubscriptionPage;

let stripePromise: Promise<any> | undefined = undefined;

function stripePromiseTest() {
    if (stripePromise) {
        return stripePromise;
    }
    // dynamically import loadStripe here to avoid loading it ahead of time (which generates a bunch of cookie errors)
    // noinspection UnnecessaryLocalVariableJS
    const loadStripe = require('@stripe/stripe-js/pure').loadStripe;
    return stripePromise = loadStripe(IS_TEST_STRIPE ? testStripePublicKey : liveStripePublicKey);
}
