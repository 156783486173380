import {checksum, shortChecksum, toBase62} from "./index";

export function normalizeString(s: string): string {
    return s.toLowerCase()  // Convert all characters to lowercase
        .normalize("NFD")   // Decompose characters with accents into their base letter + accent mark
        .replace(/[\u0300-\u036f]/g, "")  // Remove all accent marks (diacritics)
        .replace(/[^a-z0-9 ]/g, "")  // Keep only lowercase letters, numbers, and spaces
        .replace(/\s+/g, " ")  // Replace multiple spaces with a single space
        .trim();  // Remove leading and trailing spaces
}

export function containsAnyOf(s: string | undefined, strings: string[]) {
    if (!s) {
        return false;
    }
    for (const string of strings) {
        if (s.includes(string)) {
            return true;
        }
    }
    return false;
}

export function toShortHash(str: string): string {
    if (!str) return '000000'
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (hash * 31 + str.charCodeAt(i)) | 0; // Multiply by 31 (common hash prime) and keep as 32-bit int
    }
    return Math.abs(hash).toString(36).padStart(6, '0')
}

export function to18nKey(s: string) {
    // remove from defaultMessage any character that can't be in a Json key
    if (!s) {
        debugger
        return '';
    }
    let safeKey = s.trim().replace(/[^a-zA-Z0-9 ]/g, '');
    if (safeKey.length > 50 || safeKey !== s) {
        safeKey = safeKey.substring(0, 50) +  /* a simple hash */ shortChecksum(s);
    }
    return safeKey;
}

export function toKey(s: string) {
    const lcCharOnly = s?.toLowerCase()?.replaceAll(/[^a-z0-9]+/g, '');
    if (!lcCharOnly) {
        return '';
    }
    return checksum(lcCharOnly);
}
