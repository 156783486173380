import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {EsApiContext} from '../../utils/equi-scrib-internal-api-context';
import {ScalesResponse} from '../../shared/types';
import {differenceInDays, eachWeekOfInterval, format, startOfDay, subDays} from 'date-fns';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import styles from './styles.module.css';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import {PostAdd as PostAddIcon} from '@mui/icons-material';
import {isArray} from 'lodash';

const LOADING = [false];

interface InfoDialogProps {
    open: boolean;
    onClose: () => void;
    description: string;
}

const InfoDialog: React.FC<InfoDialogProps> = ({ open, onClose, description }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Scale Information</DialogTitle>
        <DialogContent>
            <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
);

interface QuestionData {
    number: string;
    question: string;
    valueText: string;
    valueCode: number;
    evidence: string;
    reasoning: string;
}

interface EvidenceDialogProps {
    open: boolean;
    onClose: () => void;
    totalScore: number | null;
    questions: QuestionData[];
    date: string | null;
    scale: string;
}

const EvidenceDialog: React.FC<EvidenceDialogProps> = ({ open, onClose, totalScore, questions, date, scale }) => {
    return (
        <Dialog open={open} onClose={onClose} fullScreen PaperProps={{ className: styles.dialogPaper }}>
            <DialogTitle>{scale} Evidence for {date}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <strong>Total Score:</strong> {totalScore}
                </DialogContentText>
                {questions.map((q, index) => (
                    <div key={q.number} className={styles.questionContainer}>
                        <DialogContentText>
                            <strong>{q.number}: </strong>{q.question}
                        </DialogContentText>
                        <DialogContentText className={styles.questionDetail}>
                            <strong>Score:</strong> {q.valueText} ({q.valueCode})
                        </DialogContentText>
                        <DialogContentText className={styles.questionDetail}>
                            <strong>Evidence:</strong> {q.evidence}
                        </DialogContentText>
                        <DialogContentText className={styles.questionDetail}>
                            <strong>Reasoning:</strong> {q.reasoning}
                        </DialogContentText>
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

interface ScaleOption {
    value: string;
    label: string;
    min: number;
    max: number;
    description: string;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const formatDateString = (date: Date): string => {
    return format(date, 'MMM d');
};

const generateEmptyChartData = (scaleOptions: ScaleOption[]) => {
    const endDate = startOfDay(new Date());
    const startDate = subDays(endDate, 91); // 3 months (13 weeks)
    // Get dates in chronological order
    const weekDates = eachWeekOfInterval({ start: startDate, end: endDate });

    // Create labels with most recent date on the right
    const labels = [...weekDates].reverse().map(date => formatDateString(date));
    const emptyData = Array(weekDates.length).fill(null);

    return {
        labels,
        datasets: scaleOptions.map(option => ({
            label: option.value.toUpperCase(),
            data: [...emptyData],
            backgroundColor: 'white'
        }))
    };
};

interface ChartDataset {
    label: string;
    data: (number | null)[];
    backgroundColor: string;
}

interface ChartData {
    labels: string[];
    datasets: ChartDataset[];
}

interface WelcomeScreenProps {
    onConsent: () => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ onConsent }) => {
    return (
        <Box className={styles.welcomeContainer}>
            <Typography variant="h4" gutterBottom>
                Welcome to Trends
            </Typography>
            <Typography variant="body1" paragraph>
                Explore your well-being over time with our Trends feature.
            </Typography>
            <Box className={styles.chartPreview}>
                <Bar
                    data={{
                        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
                        datasets: [{
                            label: 'Sample Data',
                            data: [12, 19, 3, 5],
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        }]
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                    }}
                />
            </Box>
            <Typography variant="body2" sx={{ marginTop: 2, marginBottom: 2 }}>
                To show your mental health trends over time, EquiQuill needs to store summary data from your journal
                entries on our servers. This data is not fully end-to-end encrypted, which means, in rare cases,
                authorized EquiQuill staff could see summary insights (like trend scores and explanations).
            </Typography>
            <Typography variant="body2" sx={{ marginTop: 2, marginBottom: 2 }}>
                However, your actual journal entries stay private and encrypted, and strict security rules prevent
                unauthorized access. Every access is logged and monitored to keep your data safe.
            </Typography>
            <Button variant="contained" color="primary" onClick={onConsent}>
                I Consent
            </Button>
        </Box>
    );
};

const Trends: React.FC = () => {
    interface ScaleData {
        date: Date;
        scales: NonNullable<ScalesResponse['scales']>;
    }
    
    const esApi = useContext(EsApiContext);
    const navigate = useNavigate();
    const [scaleOptions, setScaleOptions] = useState<ScaleOption[]>([]);
    const [selectedScale, setSelectedScale] = useState<string>('');
    const [scalesData, setScalesData] = useState<ScaleData[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [chartData, setChartData] = useState<ChartData>({ labels: [], datasets: [] });
    const [loadingDate, setLoadingDate] = useState<Date | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [evidenceDialogOpen, setEvidenceDialogOpen] = useState(false);
    const [selectedEvidence, setSelectedEvidence] = useState<{
        totalScore: number | null;
        questions: QuestionData[];
        date: string | null;
    }>({ totalScore: null, questions: [], date: null });
    const [questionnairesLoaded, setQuestionnairesLoaded] = useState(false);
    const [trendsConsent, setTrendsConsent] = useState<boolean|undefined>(undefined);
    const [hasEnoughEntries, setHasEnoughEntries] = useState(false);

    useEffect(() => {
        const checkTrendsConsent = async () => {
            try {
                const settings = await esApi.getSettings();
                setTrendsConsent(settings.trendsConsent || false);
            } catch (err) {
                console.error('Failed to fetch trends consent:', err);
            }
        };

        checkTrendsConsent();
    }, [esApi]);

    const handleConsent = async () => {
        try {
            const currentSettings = await esApi.getSettings();
            await esApi.updateSettings({ 
                ...currentSettings,
                trendsConsent: true 
            });
            setTrendsConsent(true);
        } catch (err) {
            console.error('Failed to update trends consent:', err);
            setError('Failed to update consent. Please try again.');
        }
    };

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            try {
                const response = await esApi.getQuestionnaires();
                if (isArray(response)) {
                    const options = response.map(q => ({
                        value: q.name,
                        label: q.label,
                        min: q.minValue,
                        max: q.maxValue,
                        description: q.interpretation
                    }));
                    setScaleOptions(options);
                    setChartData(generateEmptyChartData(options));
                    setQuestionnairesLoaded(true);
                }
            } catch (err) {
                console.error('Failed to fetch questionnaires:', err);
                setError('Failed to fetch questionnaires');
            }
        };

        fetchQuestionnaires();
    }, [esApi]);

    const handleInfoClick = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleEvidenceDialogClose = () => {
        setEvidenceDialogOpen(false);
    };

    const updateChartData = useCallback((scaleDataPoint: ScaleData) => {
        setChartData((prevChartData: ChartData) => {
            const newChartData = { ...prevChartData };
            const dateIndex = newChartData.labels.indexOf(formatDateString(scaleDataPoint.date));
            if (dateIndex !== -1) {
                newChartData.datasets.forEach((dataset) => {
                    const metric = dataset.label.toLowerCase();
                    dataset.data[dateIndex] = scaleDataPoint.scales[metric]?.QT ?? null;
                });
            }
            return newChartData;
        });
    }, []);

    useEffect(() => {
        if (scaleOptions.length > 0) {
            setChartData(generateEmptyChartData(scaleOptions));
        }
    }, [scaleOptions]);

    useEffect(() => {
        const fetchScalesData = async () => {
            if (LOADING[0]) return
            if (!selectedScale) return
            LOADING[0] = true;
            setLoading(true);
            setError(null);

            try {
                const endDate = startOfDay(new Date());
                const startDate = subDays(endDate, 91); // 3 months (13 weeks)
                // Get dates in chronological order, then reverse to process most recent first
                const weekDates = [...eachWeekOfInterval({ start: startDate, end: endDate })].reverse();

                // First get all scales
                console.log('Prefetching all scales');
                const prefetchResponse = await esApi.getAllScales();
                const prefetchedScales = prefetchResponse.scales || {};
                
                const newScalesData: ScaleData[] = [];

                for (const date of weekDates) {
                    const dateString = date.toISOString().split('T')[0].replaceAll('-', '');
                    
                    // Check if we have data for this date in prefetched scales
                    if (prefetchedScales[dateString]?.[selectedScale]) {
                        console.log(`Using prefetched scales for ${date.toLocaleDateString()}`);
                        const newScaleData = {
                            date,
                            scales: { [selectedScale]: prefetchedScales[dateString][selectedScale] }
                        };
                        newScalesData.push(newScaleData);
                        updateChartData(newScaleData);
                    } else {
                        // If not in prefetch, fetch individually
                        console.log(`Retrieving scales for ${date.toLocaleDateString()}`);
                        setLoadingDate(date);
                        const response = await esApi.getScales(date, selectedScale);
                        if (response.scales && Object.keys(response.scales).length > 0) {
                            console.log(`Retrieved scales for ${date.toLocaleDateString()}`);
                            const newScaleData = {
                                date,
                                scales: response.scales
                            };
                            newScalesData.push(newScaleData);
                            updateChartData(newScaleData);
                        } else {
                            console.log(`No scales found for ${date.toLocaleDateString()}`);
                        }
                        setLoadingDate(null);
                    }
                }

                setScalesData(newScalesData);

                // Check for enough entries after fetching all data
                const hasEnough = checkEnoughEntries(newScalesData);
                setHasEnoughEntries(hasEnough);

            } catch (err) {
                setError('Failed to fetch scales data');
                console.error(err);
            } finally {
                setLoading(false);
                LOADING[0] = false
                setLoadingDate(null);
            }
        };

        if (selectedScale) {
            fetchScalesData();
        }
    }, [esApi, updateChartData, selectedScale]);

    const checkEnoughEntries = (data: ScaleData[]): boolean => {
        if (data.length === 0) return false;

        const threeMonthsAgo = subDays(new Date(), 91);
        const filteredData = data.filter(entry => entry.date >= threeMonthsAgo);

        // For each entry, check if there are at least 4 entries within 14 days of it
        for (let i = 0; i < filteredData.length; i++) {
            const currentDate = filteredData[i].date;
            const entriesInTwoWeeks = filteredData.filter(entry => {
                const daysDiff = Math.abs(differenceInDays(currentDate, entry.date));
                return daysDiff <= 14;
            });

            if (entriesInTwoWeeks.length >= 4) {
                return true;
            }
        }

        return false;
    };

    if (error) return <div role="alert">Error: {error}. Please try refreshing the page.</div>;

    if (trendsConsent === undefined) {
        return <div></div>
    }

    if (!trendsConsent) {
        return <WelcomeScreen onConsent={handleConsent} />;
    }

    return (
        <>
        <div className={`${styles.mainContainer} ${scalesData.length === 0 ? styles.mainContainerEmpty : ''}`}>
            <h2>Trends</h2>
            {questionnairesLoaded && (
                <Select
                    value={selectedScale}
                    onChange={(event: SelectChangeEvent<string>) => {
                        setSelectedScale(event.target.value)
                    }}
                    sx={{ marginBottom: '10px' }}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        Choose a reflection tool to explore your well-being
                    </MenuItem>
                    {scaleOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            <div className={styles.chartContainer}>
                {selectedScale && (
                    <Button
                        onClick={handleInfoClick}
                        className={styles.chartButton}
                    >
                        {scaleOptions.find(option => option.value === selectedScale)?.label} Trend Over Time
                    </Button>
                )}
                <InfoDialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    description={scaleOptions.find(option => option.value === selectedScale)?.description || ''}
                />
                <div className={styles.chartWrapper}>
                    <div className={styles.chartInner}>
                        <div className={`${styles.loadingOverlay} ${loadingDate ? styles.visible : ''}`}>
                            <div className={styles.loadingIndicator} />
                            {loadingDate && (
                                <div className={styles.loadingText}>
                                    <p>Loading data for {format(loadingDate, 'MMM d, yyyy')}</p>
                                    <p className={styles.loadingTextSmaller}>(this can take a minute or so)</p>
                                </div>
                            )}
                        </div>
                        <Bar
                            data={{
                                ...chartData,
                                datasets: [
                                    {
                                        ...chartData.datasets.find(dataset => dataset.label.toLowerCase().includes(selectedScale)) || chartData.datasets[0],
                                        backgroundColor: (context) => {
                                            const chart = context.chart;
                                            const {ctx, chartArea} = chart;
                                            if (!chartArea) return 'rgba(200, 200, 200, 0.5)';
                                            
                                            const dataset = chartData.datasets
                                                .find(dataset => dataset.label.toLowerCase().includes(selectedScale));
                                            
                                            if (!dataset || !dataset.data || context.dataIndex === undefined) {
                                                return 'rgba(200, 200, 200, 0.5)';
                                            }
                                            
                                            const value = dataset.data[context.dataIndex];
                                            if (value === null || value === undefined) {
                                                return 'rgba(200, 200, 200, 0.5)';
                                            }
                                            
                                            const selectedScaleOption = scaleOptions.find(option => option.value === selectedScale);
                                            const max = selectedScaleOption?.max || 27;
                                            const normalizedValue = value / max;
                                            let calculatedHue;
                                            if (normalizedValue <= 0.5) {
                                                // Green to Yellow (120 to 60)
                                                calculatedHue = 120 - (120 * normalizedValue);
                                            } else {
                                                // Yellow to Red (60 to 0)
                                                calculatedHue = 60 - (60 * (normalizedValue - 0.5) * 2);
                                            }
                                            
                                            const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                                            gradient.addColorStop(0, 'hsl(120, 70%, 50%)'); // Green at bottom
                                            gradient.addColorStop(0.5, 'hsl(60, 70%, 50%)'); // Yellow in middle
                                            gradient.addColorStop(1, `hsl(${calculatedHue}, 70%, 50%)`); // Calculated color at top
                                            
                                            return gradient;
                                        }
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltip: {
                                        enabled: true,
                                        callbacks: {
                                            title: function(tooltipItems) {
                                                return tooltipItems[0].label;
                                            },
                                            label: function(context) {
                                                return `${context.dataset.label}: ${context.parsed.y}`;
                                            }
                                        }
                                    }
                                },
                                scales: {
                                    y: {
                                        min: scaleOptions.find(option => option.value === selectedScale)?.min || 0,
                                        max: scaleOptions.find(option => option.value === selectedScale)?.max,
                                        beginAtZero: true,
                                        ticks: {
                                            maxTicksLimit: 6
                                        }
                                    },
                                    x: {
                                        reverse: true,
                                        ticks: {
                                            maxRotation: 0,
                                            autoSkip: true,
                                            maxTicksLimit: 10
                                        }
                                    }
                                },
                                onClick: (event, elements) => {
                                    if (elements.length > 0) {
                                        const { index } = elements[0];
                                        const clickedLabel = chartData.labels[index];
                                        const scaleData = scalesData.find(data => 
                                            formatDateString(data.date) === clickedLabel
                                        );
                                        if (scaleData) {
                                            const scaleInfo = scaleData.scales[selectedScale];
                                            if (scaleInfo) {
                                                const totalScore = scaleInfo.QT;
                                                if (typeof totalScore === 'number') {
                                                    const questionKeys = Object.keys(scaleInfo)
                                                        .filter(key => key.startsWith('Q') && key !== 'QT')
                                                        .sort((a, b) => parseInt(a.slice(1)) - parseInt(b.slice(1)));
                                                    
                                                    const questionData = questionKeys.map(key => ({
                                                        number: key.slice(1),
                                                        question: scaleInfo[key].question || '',
                                                        valueText: scaleInfo[key].valueText || '',
                                                        valueCode: scaleInfo[key].valueCode || 0,
                                                        evidence: scaleInfo[key].evidence || '',
                                                        reasoning: scaleInfo[key].reasoning || ''
                                                    }));

                                                    setSelectedEvidence({
                                                        totalScore,
                                                        questions: questionData,
                                                        date: format(scaleData.date, 'MMMM d, yyyy')
                                                    });
                                                    setEvidenceDialogOpen(true);
                                                }
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
        <EvidenceDialog
            open={evidenceDialogOpen}
            onClose={handleEvidenceDialogClose}
            totalScore={selectedEvidence.totalScore}
            questions={selectedEvidence.questions}
            date={selectedEvidence.date}
            scale={scaleOptions.find(option => option.value === selectedScale)?.label || ''}
        />
        {!hasEnoughEntries && selectedScale && !loading && (
            <div className={styles.notEnoughEntriesOverlay}>
                <PostAddIcon 
                    className={styles.overlayIcon} 
                    onClick={() => {
                        const today = new Date();
                        const formattedDate = format(today, 'yyyyMMdd');
                        navigate(`/dayview/${formattedDate}.0`);
                    }}
                    style={{ cursor: 'pointer' }}
                />
                <Typography variant="h6">
                    Not enough journal entries yet
                </Typography>
                <Typography>
                    For Trends to work effectively, we need at least one week's worth of journal entries (containing at least 4 entries over the course of two weeks) within the last 3 months. Keep journaling, and you'll see your trends appear here soon!
                </Typography>
            </div>
        )}
        </>
    );
};

export default Trends;
