/**
 * Returns the current date in the format YYYYMMDD
 * This is timezone/utc safe, so that when running this in Ottawa past 8PM
 * it will still return the date in Ottawa, not in UTC.
 */
export function todayAsYyyyMmDd() {
    return dateAsyyyymmdd(new Date())
}

export function dateAsyyyymmdd(date: Date): string {
    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const dd = date.getDate().toString().padStart(2, '0');
    return yyyy + mm + dd;
}

export function dateAsYMDHHMMSS(date: Date|undefined = undefined): string {
    if (!date) {
        date = new Date();
    }
    const y = date.getFullYear().toString().charAt(3)
    const m = date.getMonth().toString(16);
    const dd = date.getDate().toString().padStart(2, '0')
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    const ss = date.getSeconds().toString().padStart(2, '0');
    return `${y}${m}${dd}${hh}${mm}${ss}`
}