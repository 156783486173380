import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from '@mui/material';
import styles from './styles.module.css';
import {useSimpleI18n} from '../../utils/i18n';
import {EsApiContext} from '../../utils/equi-scrib-internal-api-context';
import {OneThingResponse, ScribEntry} from '../../shared/types';

const OneThingToolbar = () => {
    const i18n = useSimpleI18n();
    const [oneThingResponse, setOneThingResponse] = useState<OneThingResponse | undefined>(undefined);
    const [showDialog, setShowDialog] = useState(false);

    const esApi = useContext(EsApiContext);

    useEffect(() => {
        esApi.getOneThing().then(
            (response: OneThingResponse) => setOneThingResponse(response),
        )
    }, []);

    return (
        <>
            {oneThingResponse?.image && oneThingResponse?.recommendation && (
                <>
                    <div className={styles.floatingToolbar}>
                        <Tooltip title={i18n("View today's one thing")}>
                            <IconButton
                                onClick={() => {
                                    setShowDialog(true);
                                    esApi.logEvent('showOneThing')
                                }}
                                className={styles.oneThingButton}
                            >
                                <img
                                    src={oneThingResponse.image}
                                    alt="One Thing"
                                    className={styles.oneThingImage}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Dialog
                        open={showDialog}
                        onClose={() => setShowDialog(false)}
                        aria-labelledby="one-thing-dialog-title"
                    >
                        <DialogTitle id="one-thing-dialog-title">
                            {i18n("Today's One Thing")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {oneThingResponse.recommendation}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowDialog(false)}>
                                {i18n("Close")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    )
}


export default OneThingToolbar;
